exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-bukr-js": () => import("./../../../src/pages/bukr.js" /* webpackChunkName: "component---src-pages-bukr-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-love-intervention-preorder-offer-js": () => import("./../../../src/pages/love-intervention-preorder-offer.js" /* webpackChunkName: "component---src-pages-love-intervention-preorder-offer-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-submissions-js": () => import("./../../../src/pages/submissions.js" /* webpackChunkName: "component---src-pages-submissions-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-the-team-js": () => import("./../../../src/pages/the-team.js" /* webpackChunkName: "component---src-pages-the-team-js" */),
  "component---src-pages-trade-information-js": () => import("./../../../src/pages/trade-information.js" /* webpackChunkName: "component---src-pages-trade-information-js" */),
  "component---src-templates-allauthors-js": () => import("./../../../src/templates/allauthors.js" /* webpackChunkName: "component---src-templates-allauthors-js" */),
  "component---src-templates-allbooks-js": () => import("./../../../src/templates/allbooks.js" /* webpackChunkName: "component---src-templates-allbooks-js" */),
  "component---src-templates-allbooksformat-js": () => import("./../../../src/templates/allbooksformat.js" /* webpackChunkName: "component---src-templates-allbooksformat-js" */),
  "component---src-templates-allnews-js": () => import("./../../../src/templates/allnews.js" /* webpackChunkName: "component---src-templates-allnews-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-imprintbooks-js": () => import("./../../../src/templates/imprintbooks.js" /* webpackChunkName: "component---src-templates-imprintbooks-js" */),
  "component---src-templates-imprintbooksformat-js": () => import("./../../../src/templates/imprintbooksformat.js" /* webpackChunkName: "component---src-templates-imprintbooksformat-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-series-js": () => import("./../../../src/templates/series.js" /* webpackChunkName: "component---src-templates-series-js" */)
}

